
// Libraries
import * as React from 'react'
import Classnames from 'classnames'
import moment from 'moment'

// Context
import NotificationContext from '../../../contexts/notification'

class AdminDashboardBallotsTableRow extends React.Component {
	static contextType = NotificationContext

	render() {
		const {ballot, isSelected, isWinner} = this.props

		let classNames = Classnames([
			'admin-table__row',
			{
				'admin-table__row--pending': ballot.status_id === 1,
			},
			{
				'admin-table__row--success': ballot.status_id === 2,
			},
			{
				'admin-table__row--rejected': ballot.status_id === 3,
			},
			{
				'admin-table__row--cannot-attend': ballot.status_id === 4,
			},
			{
				'admin-table__row--waiting': ballot.status_id === 5,
			},
			{
				'admin-table__row--cancelled': ballot.status_id === 6,
			},
			{
				'admin-table__row--checked': isSelected,
			},
			{
				'admin-table__row--winner': isWinner,
			},
		])

		return <tr className={classNames}>
			<td><a href={`/admin/events/ballots/${ballot?.id}`} target="_blank" rel="noreferrer">{ballot?.name}</a></td>
			<td>{ballot?.venue?.name}</td>
			<td>{moment(ballot?.application_deadline).format('DD/MM/YYYY')}</td>
			<td>{ballot?.ballots_count}</td>
			<td>{ballot?.tickets_requested_count}</td>
			<td>{ballot?.tickets_available}</td>
			<td>{ballot?.tickets_requested_percentage}</td>
		</tr>
	}
}

export default AdminDashboardBallotsTableRow

