
// Libraries
import * as React from 'react'
import AdminDashboardBallotsTableRow from './adminDashboardBallotsTableRow'

class AdminDashboardBallotsTable extends React.Component {
	state = {
		activeFilter: "next_7_days"
	}

	componentDidMount() {
		const isBrowser = typeof window !== "undefined";
		const urlParams = new URLSearchParams(isBrowser ? window.location.search : null)
		const activeFilter = urlParams.get('closing_ballots')
		this.setState(activeFilter && { activeFilter })
	}

	render() {
		const { ballots, emptyMessage } = this.props
		const { activeFilter } = this.state
		const ballotsToRender = ballots?.[activeFilter];

		return (
			<div className='admin-table admin-table--dashboard-ballots wrap--scrollx'>
				<h3>Ballots Closing Soon</h3>

				<div className='filters-status'>
					<a href="/admin/dashboard/?closing_ballots=next_7_days" className={activeFilter === "next_7_days" && "active"}>Next 7 days</a>
					<a href="/admin/dashboard/?closing_ballots=next_30_days" className={activeFilter === "next_30_days" && "active"}>Next 30 days</a>
				</div>


				<table className="admin-table__table">
					<thead>
					<tr>
						<th>Name</th>
						<th>Venue</th>
						<th>Closing Date</th>
						<th>Ballot Entries</th>
						<th>Tickets Requested</th>
						<th>Available</th>
						<th>%</th>
						<th><span className="sr-only">Actions</span></th>
					</tr>
					</thead>
					<tbody>
						{
							ballotsToRender?.length
								? ballotsToRender.map((ballot) => <AdminDashboardBallotsTableRow
									key={ballot.id}
									ballot={ballot} />)
								: <tr><td colSpan={100} align="center">{emptyMessage}</td></tr>
						}
					</tbody>
				</table>
			</div>
		)
	}
}

export default AdminDashboardBallotsTable

