
// Libraries
import * as React from 'react'

// Services
import ApiService from '../../../services/api'

// Components
import ReactTooltip from 'react-tooltip'

// Context
import NotificationContext from '../../../contexts/notification'

class AdminDashboardStats extends React.Component {
	state = {
		stats: {},
	}

	static contextType = NotificationContext

	render() {
		return (
			<ul className="admin-dashboard-stats">
				<li className="admin-dashboard-stat">
					<h4 className="admin-dashboard-stat__heading">Active ballots</h4>
					<span className="admin-dashboard-stat__primary" data-tip data-for="tooltip--active-ballots">{this.state?.stats?.active_ballots && this.state?.stats?.active_ballots.toLocaleString() }</span>


					<ReactTooltip id="tooltip--active-ballots" place="right" backgroundColor="#005EB8" textColor="#fff">
						<span>Count of ballots with a status of 'Pending' or 'Waiting List'.</span>
					</ReactTooltip>
				</li>

				<li className="admin-dashboard-stat">
					<h4 className="admin-dashboard-stat__heading">Active users</h4>
					<span className="admin-dashboard-stat__primary" data-tip data-for="tooltip--active-users">
						{this.state?.stats?.active_users?.total && this.state?.stats?.active_users?.total.toLocaleString()}

						<ReactTooltip id="tooltip--active-users" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Count of users that have verified their email address.</span>
						</ReactTooltip>
					</span>

					<span className="admin-dashboard-stat__line-break" aria-hidden />
					<span className="admin-dashboard-stat__secondary" data-tip data-for="tooltip--active-users--7-days">
						+{this.state?.stats?.active_users?.past_7_days && this.state?.stats?.active_users?.past_7_days.toLocaleString()}
						<span>Last 7 days</span>

						<ReactTooltip id="tooltip--active-users--7-days" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Count of users that have verified their email address within the past 7 days.</span>
						</ReactTooltip>
					</span>
					<span className="admin-dashboard-stat__secondary" data-tip data-for="tooltip--active-users--30-days">
						+{this.state?.stats?.active_users?.past_30_days && this.state?.stats?.active_users?.past_30_days?.toLocaleString()}
						<span>Last 30 days</span>

						<ReactTooltip id="tooltip--active-users--30-days" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Count of users that have verified their email address within the past 30 days.</span>
						</ReactTooltip>
					</span>
				</li>

				<li className="admin-dashboard-stat">
					<h4 className="admin-dashboard-stat__heading">Active organisations</h4>
					<span className="admin-dashboard-stat__primary" data-tip data-for="tooltip--active-organisations">
						{this.state?.stats?.organisations?.total && this.state?.stats?.organisations?.total.toLocaleString()}
						
						<ReactTooltip id="tooltip--active-organisations" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Count of organisations.</span>
						</ReactTooltip>
					</span>
					<span className="admin-dashboard-stat__line-break" aria-hidden />
					<span className="admin-dashboard-stat__secondary" data-tip data-for="tooltip--active-organisations--7-days">
						+{this.state?.stats?.organisations?.past_7_days && this.state?.stats?.organisations?.past_7_days.toLocaleString()}
						<span>Last 7 days</span>

						<ReactTooltip id="tooltip--active-organisations--7-days" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Count of organisations that have been created in the past 7 days.</span>
						</ReactTooltip>
					</span>
					<span className="admin-dashboard-stat__secondary" data-tip data-for="tooltip--active-organisations--30-days">
						+{this.state?.stats?.organisations?.past_30_days && this.state?.stats?.organisations?.past_30_days.toLocaleString()}
						<span>Last 30 days</span>

						<ReactTooltip id="tooltip--active-organisations--30-days" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Count of organisations that have been created in the past 30 days.</span>
						</ReactTooltip>
					</span>
				</li>

				<li className="admin-dashboard-stat">
					<h4 className="admin-dashboard-stat__heading">Unsold tickets</h4>
					<span className="admin-dashboard-stat__primary" data-tip data-for="tooltip--unsold-tickets">
						{this.state?.stats?.unsold_tickets?.percentage && this.state?.stats?.unsold_tickets?.percentage.toLocaleString()}
						
						<ReactTooltip id="tooltip--unsold-tickets" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Calculated by subtracting the number of tickets that have already been allocated through successful ballot entries from the total number of tickets available. The resulting value is then divided by the total number of tickets available and multiplied by 100 to obtain a percentage.</span>
						</ReactTooltip>
					</span>
					<span className="admin-dashboard-stat__line-break" aria-hidden />

					<span className="admin-dashboard-stat__secondary" data-tip data-for="tooltip--unsold-tickets--total">
						({this.state?.stats?.unsold_tickets?.total && this.state?.stats?.unsold_tickets?.total.toLocaleString()})

						<ReactTooltip id="tooltip--unsold-tickets--total" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Calculated by subtracting the number of tickets that have already been sold through successful ballot entries from the total number of tickets available.</span>
						</ReactTooltip>
					</span>

				</li>

				<li className="admin-dashboard-stat">
					<h4 className="admin-dashboard-stat__heading">Fees Received</h4>
					<span className="admin-dashboard-stat__primary"></span>
					<span className="admin-dashboard-stat__secondary" data-tip data-for="tooltip--fees-recieved--7-days">
						+£{this.state?.stats?.fees_received?.past_7_days}
						<span>Last 7 days</span>

						<ReactTooltip id="tooltip--fees-recieved--7-days" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Sum of total fees processesed via Stripe in the past 7 days.</span>
						</ReactTooltip>
					</span>
					<span className="admin-dashboard-stat__secondary" data-tip data-for="tooltip--fees-recieved--30-days">
						+£{this.state?.stats?.fees_received?.past_30_days}
						<span>Last 30 days</span>

						<ReactTooltip id="tooltip--fees-recieved--30-days" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Sum of total fees processesed via Stripe in the past 30 days.</span>
						</ReactTooltip>
					</span>
				</li>

				<li className="admin-dashboard-stat">
					<h4 className="admin-dashboard-stat__heading">Registration Growth</h4>
					<span className="admin-dashboard-stat__primary" data-tip data-for="tooltip--registration-growth">
						+{ this.state?.stats?.registration_growth?.today }
						<span>Today</span>

						<ReactTooltip id="tooltip--registration-growth" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Count of users that have verified their email address today.</span>
						</ReactTooltip>
					</span>
					<span className="admin-dashboard-stat__line-break" aria-hidden />
					<span className="admin-dashboard-stat__secondary" data-tip data-for="tooltip--registration-growth--7-days">
						+{ this.state?.stats?.registration_growth?.past_7_days }
						<span>Last 7 days</span>

						<ReactTooltip id="tooltip--registration-growth--7-days" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Count of users that have verified their email address in the past 7 days.</span>
						</ReactTooltip>
					</span>
					<span className="admin-dashboard-stat__secondary" data-tip data-for="tooltip--registration-growth--30-days">
						+{ this.state?.stats?.registration_growth?.past_30_days }
						<span>Last 30 days</span>

						<ReactTooltip id="tooltip--registration-growth--30-days" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Count of users that have verified their email address in the past 30 days.</span>
						</ReactTooltip>
					</span>

					{/* TODO: Need to insert URL to GA screen */ }
					<a className="admin-dashboard-stat__link-to-ga" href="https://analytics.google.com/analytics/web/#/p336659745/realtime/overview" target="_blank" rel="noreferrer">See on Google Analytics</a>
				</li>

				<li className="admin-dashboard-stat">
					<h4 className="admin-dashboard-stat__heading">Abandoned Registrations</h4>
					<span className="admin-dashboard-stat__primary"></span>
					<span className="admin-dashboard-stat__secondary" data-tip data-for="tooltip--abandoned-registrations--incomplete">
						{ this.state?.stats?.abandoned_registrations?.incomplete }
						<span>Incomplete</span>

						<ReactTooltip id="tooltip--abandoned-registrations--incomplete" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Count of users that are yet to have been marked as 'registration complete' within the database.</span>
						</ReactTooltip>
					</span>
					<span className="admin-dashboard-stat__secondary" data-tip data-for="tooltip--abandoned-registrations--not-verified">
						{ this.state?.stats?.abandoned_registrations?.not_verified }
						<span>Not verified</span>

						<ReactTooltip id="tooltip--abandoned-registrations--not-verified" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Count of users that have completed the registration process, but are yet to verify their email address.</span>
						</ReactTooltip>
					</span>
				</li>

				<li className="admin-dashboard-stat">
					<h4 className="admin-dashboard-stat__heading admin-dashboard-stat__heading--left" data-tip data-for="tooltip--regional-numbers">Regional Numbers</h4>
					<span className="admin-dashboard-stat__primary"></span>

					<ReactTooltip id="tooltip--regional-numbers" place="right" backgroundColor="#005EB8" textColor="#fff">
						<span>Count of users broken down by geographical regions.</span>
					</ReactTooltip>

					<ul className='admin-dashboard-stat__list'>
						{Object.keys(this.state?.stats?.regional_numbers || {}).map((region) => {
							return (
								<li>
									<span>{region}</span>
									<span>{this.state?.stats?.regional_numbers[region]}</span>
								</li>
							)
						})}
					</ul>
				</li>

				<li className="admin-dashboard-stat">
					<h4 className="admin-dashboard-stat__heading">Total Ballots Entered</h4>
					<span className="admin-dashboard-stat__primary" data-tip data-for="tooltip--total-ballots--open">
						{ this.state?.stats?.total_ballots_entered?.open }
						<span>Open</span>

						<ReactTooltip id="tooltip--total-ballots--open" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Count of ballots with a status of 'Pending'.</span>
						</ReactTooltip>
					</span>
					<span className="admin-dashboard-stat__line-break" aria-hidden />

					<span className="admin-dashboard-stat__secondary" data-tip data-for="tooltip--total-ballots--to-assign">
						{ this.state?.stats?.total_ballots_entered?.to_assign }
						<span>To assign</span>

						<ReactTooltip id="tooltip--total-ballots--to-assign" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Count of ballots with a status of 'Unsuccessful'.</span>
						</ReactTooltip>
					</span>
					<span className="admin-dashboard-stat__secondary" data-tip data-for="tooltip--total-ballots--assigned">
						{ this.state?.stats?.total_ballots_entered?.assigned }
						<span>Tickets assigned</span>

						<ReactTooltip id="tooltip--total-ballots--assigned" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Count of ballots with a status of 'Winner: Accepted'.</span>
						</ReactTooltip>
					</span>
				</li>
			</ul>
		)
	}

	componentDidMount() {
		this.getStats()
	}

	getStats = () => {
		const apiService = new ApiService()

		return apiService.get(`users/${localStorage.getItem('loggedIn')}/dashboard`, {}).then( (response) => {
			this.setState({
				stats: response.data?.admin_stats,
			})
		}).catch(err => console.error(err))
	}
}

export default AdminDashboardStats

