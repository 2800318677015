
// Libraries
import * as React from 'react'

// Components
import PrivateRoute from '../../components/privateRoute'
import LayoutAdmin from '../../components/layoutAdmin'
import Seo from '../../components/seo'
import {faListAlt} from '@fortawesome/pro-solid-svg-icons'
import AdminDashboardBallotsTableHeader from '../../components/admin/dashboard/adminDashboardBallotsTableHeader'
import AdminDashboardBallotsTable from '../../components/admin/dashboard/adminDashboardBallotsTable'
import AdminDashboardStats from '../../components/admin/dashboard/adminDashboardStats'

// Services
import ApiService from '../../services/api'

class AdminDashboardPage extends React.Component {
	state = {
		ballots: {},
		emptyMessage: 'Retrieving events ...'
	}

	render() {
		const {ballots, emptyMessage} = this.state

		return (
			<PrivateRoute admin>
				<LayoutAdmin className="page--admin-table nav-blue-half">
					<Seo title="Dashboard"/>
					<div className="admin-table__wrap">
						<AdminDashboardBallotsTableHeader titleText="Dashboard" titleIcon={faListAlt}/>

						<div className="admin-dashboard">
							<AdminDashboardStats />
							<AdminDashboardBallotsTable ballots={ballots} emptyMessage={emptyMessage} />
						</div>
					</div>
				</LayoutAdmin>
			</PrivateRoute>
		)
	}

	componentDidMount() {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		const {userId} = this.props
		this.getBallots(userId)

		Promise.all([
			this.getBallots(userId, activeFilters)
				.catch(() => addNotification('There was an error fetching the ballots.', 'error'))
		]).then(() => {
			this.setState({
				emptyMessage: "No ballots found, try adjusting your filters"
			})
		})
	}

	getBallots = () => {
		const apiService = new ApiService()
		let queryParams = ['filter']

		return apiService.get(`users/${localStorage.getItem('loggedIn')}/dashboard`, {}, queryParams)
			.then( (response) => {
				this.setState({
					ballots: response.data.admin_stats?.ballots_closing,
					emptyMessage: 'Retrieving events ...'
				})
			}).catch(err => {
				console.error(err)
				this.setState({
					emptyMessage: 'No events found'
				})
			})
	}
}

export default AdminDashboardPage

